<template>
	<v-container fluid class="dashboard-main pt-2 pb-2" style="height: calc(100vh - 85px)">
		<v-row>
			<v-col md="12">
				<p class="font-weight-bolder text-center font-size-h4 font-size-h1-lg">
					Welcome to {{ $APPNAME }}
				</p>
			</v-col>
			<v-col v-if="false" md="12">
				<v-layout class="align-center">
					<v-flex md8>
						<div class="listing-select">
							<div class="v-select__selections">
								<div class="v-select__selection py-3">Assets</div>
							</div>
						</div>
					</v-flex>
					<v-flex md4>
						<table width="100%">
							<tr>
								<td class="px-2" width="60%">
									<DatePicker
										v-on:click:clear="
											filter_one.date = [];
											getAssets();
										"
										clearable
										v-model="filter_one.date"
										style="width: 100%"
										hide-top-margin
										hide-details
										:loading="assetLoading"
										:disabled="assetLoading"
									></DatePicker>
								</td>
								<td class="px-2" width="30%">
									<SelectInput
										v-on:click:clear="
											filter_one.month = null;
											getAssets();
										"
										clearable
										placeholder="Month"
										:items="months"
										v-model="filter_one.month"
										item-text="text"
										item-value="value"
										hide-details
										hide-top-margin
										:loading="assetLoading"
										:disabled="assetLoading"
									></SelectInput>
								</td>
								<td class="pl-2" width="10%">
									<v-btn
										:loading="assetLoading"
										:disabled="assetLoading"
										color="blue darken-4 white--text"
										class="w-100"
										v-on:click="getAssets()"
										depressed
										tile
										>Search</v-btn
									>
								</td>
							</tr>
						</table>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table">
					<template v-slot:default>
						<thead>
							<tr>
								<th>Asset ID</th>
								<th>Asset Name</th>
								<th>Service Status</th>
								<th>Department</th>
								<th>Manufacturer</th>
								<th>Location</th>
								<th>Model No</th>
								<th>Serial No</th>
								<th>Next Calibration Date</th>
								<th>Calibration Frequency</th>
								<th>Calibration Point and Tolerance</th>
								<th>Cost Centre</th>
								<th>Previous Vendor</th>
							</tr>
						</thead>
						<tbody v-if="assets.rows.length">
							<tr
								v-for="(row, index) in assets.rows"
								:key="index"
								class="cursor-pointer"
								v-on:click="go_asset_detail(row)"
							>
								<td><ShowValue :object="row" object-key="id_number" label="asset id"></ShowValue></td>
								<td><ShowValue :object="row" object-key="name" label="asset name"></ShowValue></td>
								<td>
									<Chip
										:color="row.service_color"
										:text="row.service_status"
										:tooltip-text="row.service_tooltip"
									></Chip>
								</td>
								<td><ShowValue :object="row" object-key="department" label="department"></ShowValue></td>
								<td>
									<ShowValue :object="row" object-key="manufacturer" label="manufacturer"></ShowValue>
								</td>
								<td><ShowValue :object="row" object-key="location" label="location"></ShowValue></td>
								<td><ShowValue :object="row" object-key="model_no" label="model no"></ShowValue></td>
								<td><ShowValue :object="row" object-key="serial_no" label="serial no"></ShowValue></td>
								<td>
									<ShowValue
										:object="row"
										object-key="next_calibration_date_formatted"
										label="next calibration date"
									></ShowValue>
								</td>
								<td>
									<ShowValue
										:object="row"
										object-key="calibration_frequency_formatted"
										label="calibration frequency"
									></ShowValue>
								</td>
								<td>
									<ShowValue
										:object="row"
										object-key="calibration_point_and_tolerance"
										label="calibration point and tolerance"
									></ShowValue>
								</td>
								<td><ShowValue :object="row" object-key="cost_centre" label="cost centre"></ShowValue></td>
								<td>
									<ShowValue :object="row" object-key="previous_vendor" label="previous vendor"></ShowValue>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="assetLoading">
								<td align="center" colspan="13">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="13">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no assets at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ assets.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								color="blue darken-4"
								v-model="assets.current_page"
								:length="assets.total_page"
								:total-visible="7"
								:disabled="assetLoading"
								v-on:input="getPagination('asset', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
			<v-col v-if="false" md="12">
				<v-layout class="mt-4 align-center">
					<v-flex md8>
						<div class="listing-select">
							<div class="v-select__selections">
								<div class="v-select__selection py-3">Scheduled Services</div>
							</div>
						</div>
					</v-flex>
					<v-flex md4>
						<table width="100%">
							<tr>
								<td class="px-2" width="60%">
									<DatePicker
										v-on:click:clear="
											filter_two.date = [];
											getScheduledServices();
										"
										clearable
										v-model="filter_two.date"
										style="width: 100%"
										hide-top-margin
										hide-details
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
									></DatePicker>
								</td>
								<td class="px-2" width="30%">
									<SelectInput
										v-on:click:clear="
											filter_two.month = null;
											getScheduledServices();
										"
										clearable
										placeholder="Month"
										:items="months"
										v-model="filter_two.month"
										item-text="text"
										item-value="value"
										hide-details
										hide-top-margin
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
									></SelectInput>
								</td>
								<td class="pl-2" width="10%">
									<v-btn
										:loading="scheduledLoading"
										:disabled="scheduledLoading"
										color="blue darken-4 white--text"
										v-on:click="getScheduledServices()"
										class="w-100"
										depressed
										tile
										>Search</v-btn
									>
								</td>
							</tr>
						</table>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table">
					<template v-slot:default>
						<thead>
							<tr>
								<th>Asset ID</th>
								<th>Asset Name</th>
								<th>Service Status</th>
								<th>Eng/Sub Con</th>
								<th>Date (Expected)</th>
								<th>Date (Actual)</th>
								<th>Department</th>
								<th>Manufacturer</th>
								<th>Location</th>
								<th>Model No</th>
								<th>Serial No</th>
								<th>Next Calibration Date</th>
								<th>Calibration Frequency</th>
								<th>Calibration Point And Tolerance</th>
								<th>Cost Centre</th>
								<th>Previous Vendor</th>
							</tr>
						</thead>
						<tbody v-if="scheduled_services.rows.length">
							<tr
								v-for="(row, index) in scheduled_services.rows"
								class="cursor-pointer"
								:key="index"
								v-on:click="go_service_detail(row)"
							>
								<td><ShowValue :object="row" object-key="id_number" label="asset id"></ShowValue></td>
								<td><ShowValue :object="row" object-key="name" label="asset name"></ShowValue></td>
								<td>
									<Chip
										:color="row.service_color"
										:text="row.service_status"
										:tooltip-text="row.service_tooltip"
									></Chip>
								</td>
								<td><ShowValue :object="row" object-key="eng_sub_con" label="eng/sub con"></ShowValue></td>
								<td>
									<div class="d-flex flex-wrap">
										<div>
											<span class="bold-600 mr-1">Start:</span
											><ShowValue
												:object="row"
												object-key="expected_start_date_formatted"
												label="Start Date (Expected)"
											></ShowValue>
										</div>
										<div>
											<span class="bold-600 mr-1">End:</span
											><ShowValue
												:object="row"
												object-key="expected_end_date_formatted"
												label="End Date (Expected)"
											></ShowValue>
										</div>
									</div>
								</td>
								<td>
									<div class="d-flex flex-wrap">
										<div>
											<span class="bold-600 mr-1">Start:</span
											><ShowValue
												:object="row"
												object-key="actual_start_date_formatted"
												label="Start Date (Actual)"
											></ShowValue>
										</div>
										<div>
											<span class="bold-600 mr-1">End:</span
											><ShowValue
												:object="row"
												object-key="actual_end_date_formatted"
												label="End Date (Actual)"
											></ShowValue>
										</div>
									</div>
								</td>
								<td><ShowValue :object="row" object-key="department" label="department"></ShowValue></td>
								<td>
									<ShowValue :object="row" object-key="manufacturer" label="manufacturer"></ShowValue>
								</td>
								<td><ShowValue :object="row" object-key="location" label="location"></ShowValue></td>
								<td><ShowValue :object="row" object-key="model_no" label="model no"></ShowValue></td>
								<td><ShowValue :object="row" object-key="serial_no" label="serial no"></ShowValue></td>
								<td>
									<ShowValue
										:object="row"
										object-key="next_calibration_date_formatted"
										label="next calibration date"
									></ShowValue>
								</td>
								<td>
									<ShowValue
										:object="row"
										object-key="calibration_frequency_formatted"
										label="calibration frequency"
									></ShowValue>
								</td>
								<td>
									<ShowValue
										:object="row"
										object-key="calibration_point_and_tolerance"
										label="calibration point and tolerance"
									></ShowValue>
								</td>
								<td><ShowValue :object="row" object-key="cost_centre" label="cost centre"></ShowValue></td>
								<td>
									<ShowValue :object="row" object-key="previous_vendor" label="previous vendor"></ShowValue>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="scheduledLoading">
								<td align="center" colspan="15">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="15">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no scheduled services at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ scheduled_services.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								:disabled="scheduledLoading"
								color="blue darken-4"
								v-model="scheduled_services.current_page"
								:length="scheduled_services.total_page"
								:total-visible="7"
								v-on:input="getPagination('service-scheduled', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
			<v-col v-if="false" md="6">
				<v-layout class="mt-4 align-center">
					<v-flex md4>
						<div class="listing-select">
							<div class="v-select__selections">
								<div class="v-select__selection py-3">Completed Services</div>
							</div>
						</div>
					</v-flex>
					<v-flex md8>
						<table width="100%">
							<tr>
								<td class="px-2" width="50%">
									<DatePicker
										v-on:click:clear="
											filter_three.date = [];
											getCompletedServices();
										"
										clearable
										v-model="filter_three.date"
										style="width: 100%"
										hide-top-margin
										hide-details
										:loading="completedLoading"
										:disabled="completedLoading"
									></DatePicker>
								</td>
								<td class="px-2" width="40%">
									<SelectInput
										v-on:click:clear="
											filter_three.month = [];
											getCompletedServices();
										"
										clearable
										placeholder="Month"
										:items="months"
										v-model="filter_three.month"
										item-text="text"
										item-value="value"
										hide-details
										hide-top-margin
										:loading="completedLoading"
										:disabled="completedLoading"
									></SelectInput>
								</td>
								<td class="pl-2" width="10%">
									<v-btn
										:loading="completedLoading"
										:disabled="completedLoading"
										color="blue darken-4 white--text"
										v-on:click="getCompletedServices()"
										class="w-100"
										depressed
										tile
										>Search</v-btn
									>
								</td>
							</tr>
						</table>
					</v-flex>
				</v-layout>
				<v-simple-table class="bt-table listing-table">
					<template v-slot:default>
						<thead>
							<tr>
								<th>Asset ID</th>
								<th>Asset Name</th>
								<th>Service Status</th>
								<th>Service Done By</th>
								<th>Service Acknowledged By</th>
								<th>Invoice Status</th>
								<th>GR Date</th>
								<th>Payment Date</th>
							</tr>
						</thead>
						<tbody v-if="completed_services.rows.length">
							<tr
								v-for="(row, index) in completed_services.rows"
								class="cursor-pointer"
								:key="index"
								v-on:click="go_service_detail(row)"
							>
								<td><ShowValue :object="row" object-key="id_number" label="asset id"></ShowValue></td>
								<td><ShowValue :object="row" object-key="name" label="asset name"></ShowValue></td>
								<td>
									<Chip
										:color="row.service_color"
										:text="row.service_status"
										:tooltip-text="row.service_tooltip"
									></Chip>
								</td>
								<td>
									<ShowValue :object="row" object-key="eng_sub_con" label="service done by"></ShowValue>
								</td>
								<td>
									<ShowValue
										:object="row"
										object-key="acknowledge_by"
										label="service acknowledged by"
									></ShowValue>
								</td>
								<td>
									<Chip
										:color="row.invoice_color"
										:text="row.invoice_status"
										:tooltip-text="row.invoice_tooltip"
									></Chip>
								</td>
								<td>
									<ShowValue
										:object="row"
										object-key="invoice_gr_date_formatted"
										label="gr date"
									></ShowValue>
								</td>
								<td>
									<ShowValue
										:object="row"
										object-key="payment_date_formatted"
										label="payment date"
									></ShowValue>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="completedLoading">
								<td align="center" colspan="8">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="8">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no completed services at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ completed_services.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								:disabled="completedLoading"
								color="blue darken-4"
								v-model="completed_services.current_page"
								:length="completed_services.total_page"
								:total-visible="7"
								v-on:input="getPagination('service-completed', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
			<v-col v-if="false" md="6">
				<div class="mt-4 listing-select">
					<div class="v-select__selections">
						<div class="v-select__selection py-3">Certificates</div>
					</div>
				</div>
				<v-simple-table class="bt-table listing-table">
					<template v-slot:default>
						<thead>
							<tr>
								<th>Asset ID</th>
								<th>Asset Name</th>
								<th>View Certificates</th>
								<th>Download Certificates</th>
							</tr>
						</thead>
						<tbody v-if="certificates.rows.length">
							<tr v-for="(row, index) in certificates.rows" :key="index">
								<td><ShowValue :object="row" object-key="id_number" label="asset id"></ShowValue></td>
								<td><ShowValue :object="row" object-key="name" label="asset name"></ShowValue></td>
								<td>
									<v-icon color="blue darken-4" v-on:click="downloadFile(row, 'view')">mdi-eye</v-icon>
								</td>
								<td>
									<v-icon color="blue darken-4" v-on:click="downloadFile(row, 'download')"
										>mdi-download</v-icon
									>
								</td>
							</tr>
						</tbody>
						<tfoot v-else>
							<tr v-if="certificateLoading">
								<td align="center" colspan="4">
									<br />
									<br />
									<br />
									<br />
									<v-layout>
										<v-flex md3></v-flex>
										<v-flex md6>
											<v-progress-linear
												color="blue darken-4"
												indeterminate
												rounded
												height="6"
											></v-progress-linear>
										</v-flex>
										<v-flex md3></v-flex>
									</v-layout>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
							<tr v-else>
								<td align="center" colspan="4">
									<p class="m-0 row-not-found text-center">
										<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
										Uhh... There are no certificates at the moment.
									</p>
								</td>
							</tr>
						</tfoot>
					</template>
				</v-simple-table>
				<div class="mt-4">
					<v-layout>
						<v-flex>
							<p>{{ certificates.showing_string }}</p>
						</v-flex>
						<v-flex>
							<v-pagination
								:disabled="certificateLoading"
								color="blue darken-4"
								v-model="certificates.current_page"
								:length="certificates.total_page"
								:total-visible="7"
								v-on:input="getPagination('certificates', $event)"
							></v-pagination>
						</v-flex>
					</v-layout>
				</div>
			</v-col>
		</v-row>
		<AssetServiceDetail
			v-if="false"
			:uuid="detail_uuid"
			:dialog="detail_dialog"
			v-on:close="detail_dialog = false"
		></AssetServiceDetail>
	</v-container>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DatePicker from "@/view/components/DateRangePicker";
import SelectInput from "@/view/components/SelectInput";
import ShowValue from "@/view/components/ShowValue";
import Chip from "@/view/components/Chip";
import ApiService from "@/core/services/api.service";
import AssetServiceDetail from "@/view/components/Asset-Service-Detail";
import ObjectPath from "object-path";

export default {
	name: "dashboard",
	data() {
		return {
			detail_uuid: null,
			detail_dialog: false,
			months: [
				{ value: "january", text: "January" },
				{ value: "february", text: "February" },
				{ value: "march", text: "March" },
				{ value: "april", text: "April" },
				{ value: "may", text: "May" },
				{ value: "june", text: "June" },
				{ value: "july", text: "July" },
				{ value: "august", text: "August" },
				{ value: "september", text: "September" },
				{ value: "october", text: "October" },
				{ value: "november", text: "November" },
				{ value: "december", text: "December" },
			],
			filter_one: {
				month: null,
				date: [],
			},
			filter_two: {
				month: null,
				date: [],
			},
			filter_three: {
				month: null,
				date: [],
			},
			assetLoading: false,
			assets: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
			scheduledLoading: false,
			scheduled_services: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
			completedLoading: false,
			completed_services: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
			certificateLoading: false,
			certificates: {
				rows: [],
				current_page: 1,
				showing_string: null,
				total_page: 0,
			},
		};
	},
	methods: {
		go_asset_detail({ uuid }) {
			this.$router.push({
				name: "asset-detail",
				params: { uuid },
				query: { t: new Date().getTime() },
			});
		},
		go_service_detail({ uuid }) {
			this.detail_uuid = uuid;
			this.detail_dialog = true;
		},
		getPagination(type, page) {
			if (type == "asset") {
				this.assets.current_page = page;
				this.getAssets();
			}

			if (type == "service-scheduled") {
				this.scheduled_services.current_page = page;
				this.getScheduledServices();
			}

			if (type == "service-completed") {
				this.completed_services.current_page = page;
				this.getCompletedServices();
			}

			if (type == "certificates") {
				this.certificates.current_page = page;
				this.getCertificates();
			}
		},
		getAssets() {
			this.assetLoading = true;

			const query = {
				"date-range": this.filter_one.date,
				month: this.filter_one.month,
				page: this.assets.current_page,
			};

			this.assets.rows = [];
			this.assets.current_page = 1;
			this.assets.showing_string = null;
			this.assets.total_page = 0;

			ApiService.query(`dashboard/assets`, query)
				.then((output) => {
					this.assets.rows = ObjectPath.get(output, "data.tbody", []);
					this.assets.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.assets.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.assets.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.assetLoading = false;
				});
		},
		getScheduledServices() {
			this.scheduledLoading = true;

			const query = {
				"date-range": this.filter_two.date,
				month: this.filter_two.month,
				page: this.scheduled_services.current_page,
			};

			this.scheduled_services.rows = [];
			this.scheduled_services.current_page = 1;
			this.scheduled_services.showing_string = null;
			this.scheduled_services.total_page = 0;

			ApiService.query(`dashboard/scheduled-services`, query)
				.then((output) => {
					this.scheduled_services.rows = ObjectPath.get(output, "data.tbody", []);
					this.scheduled_services.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.scheduled_services.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.scheduled_services.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.scheduledLoading = false;
				});
		},
		getCompletedServices() {
			this.completedLoading = true;

			const query = {
				"date-range": this.filter_three.date,
				month: this.filter_three.month,
				page: this.completed_services.current_page,
			};

			this.completed_services.rows = [];
			this.completed_services.current_page = 1;
			this.completed_services.showing_string = null;
			this.completed_services.total_page = 0;

			ApiService.query(`dashboard/completed-services`, query)
				.then((output) => {
					this.completed_services.rows = ObjectPath.get(output, "data.tbody", []);
					this.completed_services.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.completed_services.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.completed_services.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.completedLoading = false;
				});
		},
		getCertificates() {
			this.certificateLoading = true;

			const query = { page: this.certificates.current_page };

			this.certificates.rows = [];
			this.certificates.current_page = 1;
			this.certificates.showing_string = null;
			this.certificates.total_page = 0;

			ApiService.query(`dashboard/certificates`, query)
				.then((output) => {
					this.certificates.rows = ObjectPath.get(output, "data.tbody", []);
					this.certificates.current_page = ObjectPath.get(output, "data.current_page", 1);
					this.certificates.showing_string = ObjectPath.get(output, "data.showing_string", null);
					this.certificates.total_page = ObjectPath.get(output, "data.total_page", 0);
				})
				.catch(({ response }) => {
					console.error({ response });
				})
				.finally(() => {
					this.certificateLoading = false;
				});
		},
		downloadFile(row) {
			window.open(row.url, "target");
		},
	},
	mounted() {
		this.$store.dispatch(SET_BREADCRUMB, [{ text: "Dashboard", disabled: true }]);
		/* this.getAssets();
		this.getScheduledServices();
		this.getCompletedServices();
		this.getCertificates(); */
	},
	components: {
		Chip,
		ShowValue,
		DatePicker,
		SelectInput,
		AssetServiceDetail,
	},
};
</script>
